<template>
    <div>
        <ul class="status_ul flex" :style="{ 'width': width ? width + 'px' : '520px' }">
            <li :class="order_status.id === item.id ? 'active' : ''" v-for="(item, index) in statusList" :key="index"
                @click="statusTab(item)">
                {{ item.title }}
                <el-badge :value="item.num" class="item" v-if="item.id !== '' && item.id != 2 && item.num" :max="99">

                </el-badge>
                <!-- <span class="status_num" v-if="item.id !== '' && item.id != 2 && item.num">{{
                item.num
            }}</span> -->

            </li>
        </ul>
    </div>
</template>

<script>
import { getOrderNum } from '@/util/orderNum'
export default {
    mounted() {
        this.statusList = JSON.parse(JSON.stringify(this.$props.status_list))
        console.log(this.order_status);
        this.getNum()
    },
    props: {
        width: String,
        status_list: {
            type: [Array],
            default: ''
        },
        order_status: Object,
        type: String,
        category_id: {
            type: [Number],
            default: ''
        }
    },
    data() {
        return {
            statusList: [],
        }
    },
    methods: {
        getNum() {
            if (this.category_id) {
                getOrderNum(this.statusList, this.type, this.category_id)

            } else {
                getOrderNum(this.statusList, this.type)
            }
            console.log(this.statusList);
        },
        statusTab(item) {
            this.getNum()
            // this.status = item
            this.$emit('statusTab', item)

        },
    },
}
</script>

<style lang=scss scoped>
::v-deep .item {
    position: absolute;
    right: -10px;
    top: -11px;
    z-index: 10;

}

::v-deep .el-badge__content {
    background: #ff0000;
}

.status_ul {
    justify-content: space-between;


    // justify-content: flex-end;
    li {
        position: relative;
        padding: 0.5rem 0.7rem;
        box-sizing: border-box;
        text-align: center;
        border-radius: 0.42rem;
        background: #ffffff;
        box-shadow: 2px 2px 4px rgba(122, 122, 122, 0.25);
        cursor: pointer;
        color: #108cdd;
        margin-left: .5rem;
    }

    .active {
        background: #108cdd;
        border-radius: 0.42rem;
        color: #fff;
    }
}

.status_num {
    position: absolute;
    right: -0.4rem;
    top: -0.5rem;
    width: 1.3rem;
    text-align: center;
    line-height: 1.3rem;
    background: #ff0000;
    border-radius: 50%;
    color: #fff;
}
</style>