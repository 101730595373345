// 刷新个人数据
import { curlGet } from '../api/request'
import $util from './util'
export function refresh_user_info() {
    let $user_info = JSON.parse(localStorage.getItem('user_info'))
    if ($user_info) {
        curlGet('/api/user/info').then((res) => {
            if (res.data.code) {
                $util.setLocalStorage('user_info', res.data.data) // 写入local storage
                return res.data.data
            }
        })
    }
}